import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';

interface ThemeProviderProps {
  children: React.ReactNode;
  theme: Theme;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme }) => {
  document.body.style.backgroundColor = theme.palette.background.default;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
