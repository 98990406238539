import { Action } from '@amzn/studios-origin-view-app-auth/constants';
import React from 'react';

import { usePermission } from '../../hooks/auth';

interface Props {
  children: React.ReactNode;
  action: Action;
  unauthorizedPlaceholder?: React.ReactNode;
}

export const PermissionGate: React.FC<Props> = ({ action, children, unauthorizedPlaceholder = null }) => {
  const { hasPermission } = usePermission(action);
  if (!hasPermission) {
    return unauthorizedPlaceholder as React.ReactElement;
  }

  return children as React.ReactElement;
};
