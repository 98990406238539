import { AccessDeniedError } from '@amzn/sitc-frontend/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const accessRequestLink = 'https://support.amazonstudios.com/hc/en-us/requests/new';

export const AccessDenied: React.FC = () => {
  const { t } = useTranslation();

  const onAction = () => {
    window.open(accessRequestLink, '_blank', 'noopener noreferrer');
  };

  return <AccessDeniedError actionLabel={t('error.access_denied.actionLabel')} onAction={onAction} />;
};
