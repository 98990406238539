import { UniversalHeaderConfig } from '@amzn/sitc-frontend/hooks';

import { universalHeaderConfig } from '../configs/universal-header';

export const useUniversalHeaderConfig = (overrides: Partial<UniversalHeaderConfig> = {}): UniversalHeaderConfig => {
  const { productIdentifieri18nKey, ...universalHeaderProps } = universalHeaderConfig;
  return {
    originSearch: {
      enable: true,
      getSearchResultNavigatePath(searchResult) {
        return `/titles/${searchResult.documentIdentifier}`;
      },
    },
    ...universalHeaderProps,
    ...overrides,
  };
};
