import '../i18n';

import { FeatureGate, RumBoundary } from '@amzn/sitc-frontend/components';
import { MicroAppConfigProvider, RumProvider } from '@amzn/sitc-frontend/contexts';
import { useUniversalHeader } from '@amzn/sitc-frontend/hooks';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { MUI_LICENSE_KEY } from '@amzn/studios-material-ui-x/src/globals';
import { Action } from '@amzn/studios-origin-view-app-auth/constants';
import { LicenseInfo } from '@mui/x-license-pro';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { microAppName } from '../configs/app';
import { ApolloProvider } from '../context/Apollo';
import { AuthSessionProvider } from '../context/Auth';
import { FeatureFlagsProvider } from '../context/FeatureFlags';
import { MicroAppInitializationProvider } from '../context/MicroAppInitialization';
import { ThemeProvider } from '../context/Theme';
import { useAppTitle } from '../hooks/app-title';
import { useUniversalHeaderConfig } from '../hooks/universal-header';
import { AccessDenied } from '../pages/error/AccessDenied';
import { NotFound } from '../pages/error/NotFound';
import { appDomain, MicroAppConfigContext, MicroAppConfigResultType } from '../utils/app';
import { AppLoading } from './common/AppLoading';
import { PermissionGate } from './common/PermissionGate';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const DataGrid = React.lazy(() => import('../pages/DataGridPage'));
const TitleDetail = React.lazy(() => import('../pages/TitleDetailPage'));

type OriginViewRoutesProps = {
  radio: Radio;
  stage: string;
};

const OriginViewRoutes: React.FC<OriginViewRoutesProps> = ({ radio, stage }) => {
  const universalHeaderConfig = useUniversalHeaderConfig();
  useUniversalHeader({ radio, microAppName, universalHeaderConfig });

  return (
    <Routes>
      <Route path="/" element={<DataGrid />} />
      <Route path="/titles/:titleVertexId/" element={<TitleDetail stage={stage} />} />
      <Route path="/titles/:titleVertexId/seasons/:seasonVertexId" element={<TitleDetail stage={stage} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const StudiosOriginViewApp: React.FC<MicroAppInitializationProps> = (props: MicroAppInitializationProps) => {
  const {
    basePath,
    authSession,
    appId,
    microAppName: deprecatedMicroAppName,
    microAppVersion,
    stage,
    radio,
    featureOverrides,
    userPreferences,
    theme = backlotLightTheme,
  } = props;

  useAppTitle({ appChannel: radio.getChannel('app'), routePrefix: basePath });

  return (
    <ThemeProvider theme={theme}>
      <MicroAppInitializationProvider
        authSession={authSession}
        basePath={basePath}
        stage={stage}
        appId={appId}
        radio={radio}
        featureOverrides={featureOverrides}
        microAppName={deprecatedMicroAppName}
        microAppVersion={microAppVersion}
        userPreferences={userPreferences}>
        <MicroAppConfigProvider<MicroAppConfigResultType>
          stage={stage}
          appName={microAppName}
          appId={appId}
          appDomain={appDomain}
          appLoader={<AppLoading />}
          context={MicroAppConfigContext}>
          <RumProvider<MicroAppConfigResultType> microAppConfigContext={MicroAppConfigContext}>
            <RumBoundary>
              <FeatureFlagsProvider featureOverrides={featureOverrides}>
                <FeatureGate feature="originView">
                  <I18nextProvider i18n={i18n}>
                    <BrowserRouter basename={basePath}>
                      <AuthSessionProvider authSession={authSession}>
                        <ApolloProvider>
                          <PermissionGate action={Action.ViewActiveTitles} unauthorizedPlaceholder={<AccessDenied />}>
                            <Suspense fallback="loading...">
                              <OriginViewRoutes radio={radio as unknown as Radio} stage={stage} />
                            </Suspense>
                          </PermissionGate>
                        </ApolloProvider>
                      </AuthSessionProvider>
                    </BrowserRouter>
                  </I18nextProvider>
                </FeatureGate>
              </FeatureFlagsProvider>
            </RumBoundary>
          </RumProvider>
        </MicroAppConfigProvider>
      </MicroAppInitializationProvider>
    </ThemeProvider>
  );
};

export default StudiosOriginViewApp;
