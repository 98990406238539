import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { MicroAppRegistryInfo } from '@amzn/sitc-frontend-micro-app-sdk-core';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/ErrorBoundary';
import StudiosOriginViewApp from './components/StudiosOriginViewApp';

const configuration = {};

const radioConfig = {
  channelNames: ['app', 'revision-history', 'title-app-components-card-list', 'title-app-components-preview-drawer', 'universal-header'],
};

const microApps: MicroAppRegistryInfo[] = [
  {
    microAppName: 'amzn-studios-revision-history-app',
    packageName: '@amzn/studios-revision-history-app',
    version: '*',
    accessLevel: 'restricted',
  },
  {
    microAppName: 'amzn-studios-title-app-components',
    packageName: '@amzn/studios-title-app-components',
    version: '*',
    accessLevel: 'restricted',
  },
];
const rootComponent = process.env.STANDALONE
  ? () => (
      <StandaloneConfiguration
        RootComponent={StudiosOriginViewApp}
        configuration={configuration}
        radioConfig={radioConfig}
        microApps={microApps}
      />
    )
  : StudiosOriginViewApp;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  renderType: 'createRoot',
  errorBoundary: (error, errorInfo, props) => <ErrorBoundary error={error} errorInfo={errorInfo} appProps={props} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
