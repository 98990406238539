import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as ApolloClientProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import React, { useContext, useEffect, useState } from 'react';

import { AppLoading } from '../components/common/AppLoading';
import { MicroAppConfigContext } from '../utils/app';
import { AuthSessionContext } from './Auth';

const cache = new InMemoryCache({});

interface ApolloProviderProps {
  children?: React.ReactNode;
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ children }) => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const { apiBaseUrl, appId } = useContext(MicroAppConfigContext);
  const { authSession } = useContext(AuthSessionContext);

  useEffect(() => {
    if (authSession) {
      const isdgLink = createHttpLink({
        uri: `${apiBaseUrl}/api/graphql/domain-authority/isdg`,
      });

      const restLink = new RestLink({ uri: `${apiBaseUrl}/api/rest` });

      const authLink = setContext((setter, { headers }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${authSession.idToken}`,
          appid: appId,
        },
      }));

      setApolloClient(
        new ApolloClient({
          link: ApolloLink.from([authLink, restLink, isdgLink]),
          cache,
        })
      );
    }
  }, [authSession]);

  if (!apolloClient) {
    return <AppLoading />;
  }

  return <ApolloClientProvider client={apolloClient}>{children}</ApolloClientProvider>;
};
