import React, { ErrorInfo } from 'react';
import { AppProps } from 'single-spa';

export interface ErrorBoundaryProps<AppProps> {
  error: Error;
  errorInfo: ErrorInfo;
  appProps: AppProps;
}

export const ErrorBoundary = ({ error, errorInfo, appProps }: ErrorBoundaryProps<AppProps>) => (
  <>
    <div>amzn-studios-origin-view-app error: {error.message}</div>
    <div>{JSON.stringify(errorInfo, null, 2)}</div>
    <div>{JSON.stringify(appProps, null, 2)}</div>
  </>
);
