import { Channel } from '@amzn/sitc-frontend/utils/radio';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UrlPattern from 'url-pattern';

interface TitleConfig {
  pattern?: UrlPattern;
  productName?: string;
  pageName?: string;
  sectionName?: string;
}

interface UseAppTitleParams {
  appChannel?: Channel;
  routePrefix?: string;
}

const titleConfigs: TitleConfig[] = [
  {
    pattern: new UrlPattern('/titles/:titleVertexId'),
    pageName: 'app.title.title_details',
  },
  {
    pattern: new UrlPattern('/'),
    pageName: 'app.title.data_grid',
  },
];

const defaultRoutePrefix = '/';

export const useAppTitle = ({ appChannel, routePrefix = defaultRoutePrefix }: UseAppTitleParams) => {
  const { t } = useTranslation();
  const { pathname } = window.location;

  useEffect(() => {
    if (!appChannel) {
      return;
    }
    const route = routePrefix !== defaultRoutePrefix ? pathname.replace(routePrefix, '') : pathname;
    const routeBasedTitleConfig = titleConfigs.find(({ pattern }) => pattern?.match(route));

    appChannel.publish('page-metadata-update', {
      payload: {
        productName: t(routeBasedTitleConfig?.productName || 'app.product_name'),
        pageName: t(routeBasedTitleConfig?.pageName || ''),
        sectionName: t(routeBasedTitleConfig?.sectionName || ''),
      },
    });
  }, [pathname]);
};
