import { Action } from '@amzn/studios-origin-view-app-auth/constants';
import { useContext } from 'react';

import { AuthSessionContext } from '../context/Auth';

interface UsePermissionReturn {
  hasPermission: boolean;
}

export const usePermission = (action: Action): UsePermissionReturn => {
  const { authSession, userAuth } = useContext(AuthSessionContext);

  if (!authSession || !userAuth) {
    return { hasPermission: false };
  }

  return { hasPermission: userAuth.evaluatePermission(action) };
};
