import { Maybe } from '@amzn/sitc-frontend-types/helper';
import { OriginViewUserAuth } from '@amzn/studios-origin-view-app-auth/user-auth';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AppLoading } from '../components/common/AppLoading';
import { AuthSession } from '../types/auth';
import { MicroAppConfigContext } from '../utils/app';

interface AuthContextValue {
  authSession: Maybe<AuthSession>;
  userAuth: Maybe<OriginViewUserAuth>;
}

const DefaultAuthContextValue: AuthContextValue = {
  authSession: null,
  userAuth: null,
};

export const AuthSessionContext = React.createContext<AuthContextValue>(DefaultAuthContextValue);

interface AuthSessionProviderProps {
  children?: React.ReactNode;
  authSession?: AuthSession;
}

export const AuthSessionProvider: React.FC<AuthSessionProviderProps> = ({ authSession, children }) => {
  const [userAuth, setUserAuth] = useState<Maybe<OriginViewUserAuth>>(null);
  const { appInitiated, appConfig } = useContext(MicroAppConfigContext);

  useEffect(() => {
    if (appInitiated && authSession) {
      setUserAuth(new OriginViewUserAuth({ customGroups: authSession?.userGroups || [] }, { stage: appConfig?.app.stage }));
    }
  }, [appInitiated, authSession]);

  const value = useMemo(
    () => ({
      authSession,
      userAuth,
    }),
    [authSession, userAuth]
  );

  if (!authSession) {
    return <AppLoading />;
  }

  return <AuthSessionContext.Provider value={value}>{children}</AuthSessionContext.Provider>;
};
