import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { MaybeNull } from '@amzn/sitc-frontend-types/helper';
import React from 'react';

export interface OriginEditAppProps extends MicroAppInitializationProps {
  children: React.ReactNode;
}

export const MicroAppInitializationContext = React.createContext<MaybeNull<MicroAppInitializationProps>>(null);

export const MicroAppInitializationProvider: React.FC<OriginEditAppProps> = ({ children, ...props }) => (
  <MicroAppInitializationContext.Provider value={props}>{children}</MicroAppInitializationContext.Provider>
);
