import type { UniversalHeaderConfig } from '@amzn/sitc-frontend/hooks';

type OriginViewHeaderConfig = Omit<UniversalHeaderConfig, 'originSearch'> & {
  productIdentifieri18nKey: string;
};

export const universalHeaderConfig: OriginViewHeaderConfig = {
  productIdentifieri18nKey: 'app.universal_header.product_identifier',
  productIdentifier: 'Origin View',
};

export const universalHeaderPixelHeight = 80;
