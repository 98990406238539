import { FeatureFlagsProvider as BaseFeatureFlagProvider } from '@amzn/sitc-frontend/contexts';
import { Features } from '@amzn/sitc-frontend/types';
import React, { useContext } from 'react';

import { MicroAppConfigContext } from '../utils/app';

interface FeatureFlagsProviderProps {
  featureOverrides: Features;
  children: React.ReactNode;
}

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({ featureOverrides, children }) => {
  const { appConfig } = useContext(MicroAppConfigContext);

  return (
    <BaseFeatureFlagProvider featureOverrides={featureOverrides} defaultFeatures={appConfig?.app.features}>
      {children}
    </BaseFeatureFlagProvider>
  );
};
