export const en = {
  'micro_app.showing': 'Showing {{name}}',
  'app.product_name': 'Origin View',
  'app.title.data_grid': 'Data Grid',
  'app.title.title_details': 'Title Details',
  'app.loading': 'Loading Origin View',
  'app.cancel': 'Cancel',
  'app.share': 'Share',
  'app.share.tooltip': 'Share link',
  'app.edit': 'Edit',
  'app.activity_feed': 'Activity Feed',
  'app.universal_header.product_identifier': 'Origin View',
  'error.access_denied.actionLabel': 'Ask for Access',
  'error.access_denied.errorCode': '403',
  'error.access_denied.errorCodeDescription': 'Access denied',
  'error.access_denied.longDescription': 'You do not have permission to view this page.',
  'error.not_found.actionLabel': 'Back to Homepage',
  'error.not_found.errorCode': '404',
  'error.not_found.errorCodeDescription': 'Page not found',
  'error.not_found.longDescription': "We can't seem to find the page you're looking for.",
  'error.unexpected.actionLabel': 'Back to Homepage',
  'error.unexpected.errorCode': '500',
  'error.unexpected.errorCodeDescription': 'Something went wrong...',
  'universal_header.help.online_help_desk.primary': 'Online Help Desk',
  'universal_header.help.online_help_desk.secondary': 'support.amazonstudios.com',
  'universal_header.help.email.primary': 'Email',
  'universal_header.help.email.secondary': 'support@amazonstudios.com',
  'universal_header.help.call_or_text.primary': 'Call or Text',
  'universal_header.help.call_or_text.secondary': '855_907_4955 (US)',
  'universal_header.appswitcher.product.primary': 'Product Name',
  'universal_header.appswitcher.product.secondary': 'One line app definition can go here',
  'universal_header.appswitcher.studiosapps.primary': 'StudiosApps',
  'universal_header.appswitcher.studiosapps.secondary': 'Track, store, and share operational data',
  'universal_header.appswitcher.media_supply_chain.primary': 'Media Supply Chain',
  'universal_header.appswitcher.media_supply_chain.secondary': 'Manage creative media assets',
  'universal_header.appswitcher.studios_content_access.primary': 'Studios Content Access',
  'universal_header.appswitcher.studios_content_access.secondary': 'Submit and approve access requests',
  'universal_header.appswitcher.almanac.primary': 'Almanac',
  'universal_header.appswitcher.almanac.secondary': 'Vertical release calendar and Gap Finder',
  'universal_header.appswitcher.tooltip': 'App Switcher',
  'universal_header.profile.profile_info.primary': 'Jane Doe',
  'universal_header.profile.profile_info.secondary': 'Optional Secondary Text',
  'universal_header.profile.profile_settings.preferences.primary': 'Profile Preferences',
  'universal_header.profile.profile_settings.notifications.primary': 'Notification Settings',
  'universal_header.profile.profile_settings.notifications.secondary': 'Paused \u2022 Until Tomorrow',
  'universal_header.profile.profile_settings.localization.primary': 'Localization Settings',
  'universal_header.profile.profile_settings.localization.secondary': 'Pacific Daylight Time',
  'universal_header.notification.header.tooltip': 'Notifications',
  'universal_header.notification.header.count_one': '{{count}} Notification',
  'universal_header.notification.header.count_other': '{{count}} Notifications',
  'universal_header.notification.header.read_all': 'Mark All As Read',
  'universal_header.notification.footer.view_all': 'View All',
  'datagrid.header.catalog': 'Catalog',
  'datagrid.empty_results.no_matching_records': 'No matching records found',
  'datagrid.empty_results.advice': 'Adjust your filter or search criteria to be less specific and try again.',
  'datagrid.header.active_titles_label': 'Active Titles',
  'datagrid.display_field.alternate_id': 'Alternate ID',
  'datagrid.display_field.alternate_id_descriptor': 'Alternate ID Descriptor',
  'datagrid.display_field.alternate_title': 'Alternate Title',
  'datagrid.display_field.alternate_title_type': 'Alternate Title Type',
  'datagrid.display_field.branding_type': 'Branding Type',
  'datagrid.display_field.color': 'Color',
  'datagrid.display_field.content_security_tier': 'Content Security Tier',
  'datagrid.display_field.copyright': 'Copyright',
  'datagrid.display_field.copyright_holder': 'Copyright Holder',
  'datagrid.display_field.copyright_year': 'Copyright Year',
  'datagrid.display_field.country_of_origin': 'Country of Origin',
  'datagrid.display_field.credited_companies': 'Credited Companies',
  'datagrid.display_field.designation_classification': 'Designation Classification',
  'datagrid.display_field.enterprise_title_name': 'Enterprise Title Name',
  'datagrid.display_field.episode_number_from': 'Episode Number From',
  'datagrid.display_field.episode_number_to': 'Episode Number To',
  'datagrid.display_field.episode_production_number': 'Episode Production Number',
  'datagrid.display_field.episode_sequence_number': 'Episode Sequence Number',
  'datagrid.display_field.episodes_ordered': 'Episodes Ordered',
  'datagrid.display_field.episodes_produced': 'Episodes Produced',
  'datagrid.display_field.favorite': 'Favorite',
  'datagrid.display_field.genre': 'Genre',
  'datagrid.display_field.greenlight_status': 'Greenlight Status',
  'datagrid.display_field.hard_prep_end': 'Hard Prep End',
  'datagrid.display_field.hard_prep_start': 'Hard Prep Start',
  'datagrid.display_field.initial_release_date': 'Initial Release Date',
  'datagrid.display_field.library': 'Library',
  'datagrid.display_field.library_type': 'Library Type',
  'datagrid.display_field.licensor': 'Licensor',
  'datagrid.display_field.lifecycle_phase': 'Lifecycle Phase',
  'datagrid.display_field.logline': 'Logline',
  'datagrid.display_field.marketing_categorization': 'Marketing Categorization',
  'datagrid.display_field.original_language_primary': 'Original Language - Primary',
  'datagrid.display_field.originating_team': 'Originating Teams',
  'datagrid.display_field.originating_territory': 'Originating Territories',
  'datagrid.display_field.owners_av_localization': 'Owners - AV Localization',
  'datagrid.display_field.owners_av_post_production': 'Owners - AV Post Production',
  'datagrid.display_field.owners_business_affairs': 'Owners - Business Affairs',
  'datagrid.display_field.owners_casting': 'Owners - Casting',
  'datagrid.display_field.owners_clearance_legal': 'Owners - Clearance Legal',
  'datagrid.display_field.owners_consumer_insights': 'Owners - Consumer Insights',
  'datagrid.display_field.owners_content_acquisition': 'Owners - Content Acquisition',
  'datagrid.display_field.owners_creative': 'Owners - Creative',
  'datagrid.display_field.owners_creative_production': 'Owners - Creative Production',
  'datagrid.display_field.owners_delivery_asset_management': 'Owners - Delivery/Asset Management',
  'datagrid.display_field.owners_development_legal': 'Owners - Development Legal',
  'datagrid.display_field.owners_finance': 'Owners - Finance',
  'datagrid.display_field.owners_localization': 'Owners - Localization',
  'datagrid.display_field.owners_marketing_brand': 'Owners - Marketing - Brand',
  'datagrid.display_field.owners_mastering': 'Owners - Mastering',
  'datagrid.display_field.owners_music': 'Owners - Music',
  'datagrid.display_field.owners_music_ba': 'Owners - Music BA',
  'datagrid.display_field.owners_post_production': 'Owners - Post Production',
  'datagrid.display_field.owners_pr': 'Owners - PR',
  'datagrid.display_field.owners_production': 'Owners - Production',
  'datagrid.display_field.owners_production_legal': 'Owners - Production Legal',
  'datagrid.display_field.owners_risk_management': 'Owners - Risk Management',
  'datagrid.display_field.owners_security_liaison': 'Owners - Security Liaison',
  'datagrid.display_field.owners_standards_and_practices': 'Owners - Standards and Practices',
  'datagrid.display_field.owners_vfx': 'Owners - VFX',
  'datagrid.display_field.owners_x_ray_team': 'Owners - X-Ray Team',
  'datagrid.display_field.ownership_type': 'Ownership Type',
  'datagrid.display_field.physical_production_start': 'Physical Production Start',
  'datagrid.display_field.physical_production_wrap': 'Physical Production Wrap',
  'datagrid.display_field.production_company': 'Production Company',
  'datagrid.display_field.production_format': 'Production Format',
  'datagrid.display_field.rating': 'Rating',
  'datagrid.display_field.rating_type': 'Rating Type',
  'datagrid.display_field.release_cadence': 'Release Cadence',
  'datagrid.display_field.release_date': 'Release Date',
  'datagrid.display_field.release_language': 'Release Language',
  'datagrid.display_field.release_note': 'Release Note',
  'datagrid.display_field.release_platform': 'Release Platform',
  'datagrid.display_field.release_status': 'Release Status',
  'datagrid.display_field.release_territory': 'Release Territory',
  'datagrid.display_field.releasing_entity': 'Releasing Entity',
  'datagrid.display_field.rightsline_status': 'Rightsline Status',
  'datagrid.display_field.run_time_block': 'Run Time Block',
  'datagrid.display_field.scripted_unscripted_live': 'Scripted / Unscripted / Live',
  'datagrid.display_field.season_number': 'Season Number',
  'datagrid.display_field.season_part': 'Season Part',
  'datagrid.display_field.season_year': 'Season Year',
  'datagrid.display_field.season_year_to': 'Season Year To',
  'datagrid.display_field.show_code': 'Show Code',
  'datagrid.display_field.soft_prep_end': 'Soft Prep End',
  'datagrid.display_field.soft_prep_start': 'Soft Prep Start',
  'datagrid.display_field.synopsis': 'Synopsis',
  'datagrid.display_field.synopsis_source': 'Synopsis Source',
  'datagrid.display_field.synopsis_type': 'Synopsis Type',
  'datagrid.display_field.target_release_date_date': 'Target Release Date - Date',
  'datagrid.display_field.target_release_date_quarter': 'Target Release Date - Quarter',
  'datagrid.display_field.target_release_date_year': 'Target Release Date - Year',
  'datagrid.display_field.title_id': 'Title ID',
  'datagrid.display_field.title_level': 'Title Level',
  'datagrid.display_field.title_point_of_contact': 'Title Point of Contact',
  'datagrid.display_field.title_type': 'Title Type',
  'datagrid.display_field.writing_end': 'Writing End',
  'datagrid.display_field.writing_start': 'Writing Start',
  'datagrid.display_field.attachments': 'Attachments',
  'datagrid.display_field.mgm_bonus_content_notation': 'MGM Bonus Content Notation',
  'datagrid.display_field.mgm_related_ips': 'MGM Related IPs',
  'datagrid.configuration_bar.customize.trigger_label': 'Customize',
  'datagrid.configuration_bar.customize.customize_header': 'Customize Table',
  'datagrid.configuration_bar.customize.column_settings_header': 'Column Settings',
  'datagrid.configuration_bar.customize.row_height_header': 'Row Height',
  'datagrid.configuration_bar.customize.standard_label': 'Standard',
  'datagrid.configuration_bar.customize.compact_label': 'Compact',
  'datagrid.configuration_bar.customize.show_all_columns_label': 'Show all Columns',
  'datagrid.configuration_bar.customize.pinned_header': 'Pinned',
  'datagrid.configuration_bar.customize.scrolling_header': 'Scrolling',
  'datagrid.configuration_bar.customize.reset_all_label': 'Reset All',
  'datagrid.configuration_bar.filter.trigger_label': 'Filter',
  'datagrid.configuration_bar.filter.field_label': 'FIELD',
  'datagrid.configuration_bar.filter.operator_label': 'OPERATOR',
  'datagrid.configuration_bar.filter.value_label': 'VALUE',
  'datagrid.configuration_bar.filter.add_filter_label': 'Add Filter',
  'datagrid.configuration_bar.filter.clear_all_label': 'Clear All',
  'datagrid.configuration_bar.filter.apply_filters_label': 'Apply Filters',
  'datagrid.configuration_bar.matching_records_label': 'matching records',
  'datagrid.share_bar.share_view': 'Share View',
  'datagrid.share_bar.share_view.success_alert': 'Share link copied to clipboard',
  'datagrid.share_bar.request_title': 'Request Title',
  'datagrid.share_bar.add_new_title': 'Add New Title',
  'title_detail.season_label': 'Season',
  'title_detail.overview_label': 'Overview',
  'title_detail.details_label': 'Details',
  'title_detail.title_info_header': 'Title Info',
  'title_detail.alternate_id_label': 'Alternate ID',
  'title_detail.alternate_id_descriptor_label': 'Alternate ID Descriptor',
  'title_detail.alternate_title_label': 'Alternate Title',
  'title_detail.alternate_title_type_label': 'Alternate Title Type',
  'title_detail.branding_type_label': 'Branding Type',
  'title_detail.color_label': 'Color',
  'title_detail.content_security_tier_label': 'Content Security Tier',
  'title_detail.copyright_label': 'Copyright',
  'title_detail.copyright_holder_label': 'Copyright Holder',
  'title_detail.copyright_year_label': 'Copyright Year',
  'title_detail.countries_of_origin_label': 'Countries of Origin',
  'title_detail.credited_companies_label': 'Credited Companies',
  'title_detail.designation_classification_label': 'Designation Classification',
  'title_detail.enterprise_title_name_label': 'Enterprise Title Name',
  'title_detail.episode_number_from_label': 'Episode Number From',
  'title_detail.episode_number_to_label': 'Episode Number To',
  'title_detail.episode_production_number_label': 'Episode Production Number',
  'title_detail.episode_sequence_number_label': 'Episode Sequence Number',
  'title_detail.episodes_ordered_label': 'Episodes Ordered',
  'title_detail.episodes_produced_label': 'Episodes Produced',
  'title_detail.favorite_label': 'Favorite',
  'title_detail.genre_label': 'Genre',
  'title_detail.greenlight_status_label': 'Greenlight Status',
  'title_detail.hard_prep_end_label': 'Hard Prep End',
  'title_detail.hard_prep_start_label': 'Hard Prep Start',
  'title_detail.initial_release_date_label': 'Initial Release Date',
  'title_detail.library_label': 'Library',
  'title_detail.sub_library_label': 'Sub-Library',
  'title_detail.library_type_label': 'Library Type',
  'title_detail.licensor_label': 'Licensor',
  'title_detail.lifecycle_phase_label': 'Lifecycle Phase',
  'title_detail.logline_label': 'Logline',
  'title_detail.marketing_categorization_label': 'Marketing Categorization',
  'title_detail.original_language_label': 'Original Language',
  'title_detail.original_language_primary_label': 'Original Language - Primary',
  'title_detail.originating_teams_label': 'Originating Teams',
  'title_detail.originating_territory_label': 'Originating Territory',
  'title_detail.owners_av_localization_label': 'Owners - AV Localization',
  'title_detail.owners_av_post_production_label': 'Owners - AV Post Production',
  'title_detail.owners_business_affairs_label': 'Owners - Business Affairs',
  'title_detail.owners_casting_label': 'Owners - Casting',
  'title_detail.owners_clearance_legal_label': 'Owners - Clearance Legal',
  'title_detail.owners_consumer_insights_label': 'Owners - Consumer Insights',
  'title_detail.owners_content_acquisition_label': 'Owners - Content Acquisition',
  'title_detail.owners_creative_label': 'Owners - Creative',
  'title_detail.owners_creative_production_label': 'Owners - Creative Production',
  'title_detail.owners_delivery_asset_management_label': 'Owners - Delivery/Asset Management',
  'title_detail.owners_development_legal_label': 'Owners - Development Legal',
  'title_detail.owners_finance_label': 'Owners - Finance',
  'title_detail.owners_localization_label': 'Owners - Localization',
  'title_detail.owners_marketing_brand_label': 'Owners - Marketing - Brand',
  'title_detail.owners_mastering_label': 'Owners - Mastering',
  'title_detail.owners_music_label': 'Owners - Music',
  'title_detail.owners_music_ba_label': 'Owners - Music BA',
  'title_detail.owners_post_production_label': 'Owners - Post Production',
  'title_detail.owners_pr_label': 'Owners - PR',
  'title_detail.owners_production_label': 'Owners - Production',
  'title_detail.owners_production_legal_label': 'Owners - Production Legal',
  'title_detail.owners_risk_management_label': 'Owners - Risk Management',
  'title_detail.owners_security_liaison_label': 'Owners - Security Liaison',
  'title_detail.owners_standards_and_practices_label': 'Owners - Standards and Practices',
  'title_detail.owners_vfx_label': 'Owners - VFX',
  'title_detail.owners_x_ray_team_label': 'Owners - X-Ray Team',
  'title_detail.ownership_type_label': 'Ownership Types',
  'title_detail.physical_production_start_label': 'Physical Production Start',
  'title_detail.physical_production_wrap_label': 'Physical Production Wrap',
  'title_detail.principal_start_label': 'Principal Start',
  'title_detail.writing_start_label': 'Writing Start',
  'title_detail.writing_end_label': 'Writing Wrap',
  'title_detail.production_company_label': 'Production Company',
  'title_detail.production_format_label': 'Production Format',
  'title_detail.rating_label': 'Rating',
  'title_detail.rating_type_label': 'Rating Type',
  'title_detail.release_cadence_label': 'Release Cadence',
  'title_detail.release_date_label': 'Release Date',
  'title_detail.release_year_label': 'Release Year',
  'title_detail.target_amr_label': 'Target AMR',
  'title_detail.release_language_label': 'Release Language',
  'title_detail.release_note_label': 'Release Note',
  'title_detail.release_platform_label': 'Release Platform',
  'title_detail.release_status_label': 'Release Status',
  'title_detail.release_territory_label': 'Release Territory',
  'title_detail.releasing_entity_label': 'Releasing Entity',
  'title_detail.rightsline_status_label': 'Rightsline Status',
  'title_detail.run_time_block_label': 'Run Time Block',
  'title_detail.scripted_unscripted_live_label': 'Scripted / Unscripted / Live',
  'title_detail.season_number_label': 'Season Number',
  'title_detail.season_part_label': 'Season Part',
  'title_detail.season_year_label': 'Season Year',
  'title_detail.season_year_to_label': 'Season Year To',
  'title_detail.show_code_label': 'Show Code',
  'title_detail.soft_prep_end_label': 'Soft Prep End',
  'title_detail.soft_prep_start_label': 'Soft Prep Start',
  'title_detail.synopsis_header': 'Synopsis',
  'title_detail.synopsis_label': 'Synopsis',
  'title_detail.synopsis_source_label': 'Synopsis Source',
  'title_detail.synopsis_type_label': 'Synopsis Type',
  'title_detail.target_release_date_date_label': 'Target Release Date - Date',
  'title_detail.target_release_date_quarter_label': 'Target Release Date - Quarter',
  'title_detail.target_release_date_year_label': 'Target Release Date - Year',
  'title_detail.title_id_label': 'Title ID',
  'title_detail.title_level_label': 'Title Level',
  'title_detail.title_point_of_contact_label': 'Title Point of Contact',
  'title_detail.title_type_label': 'Title Type',
  'title_detail.attachments_label': 'Attachments',
  'title_detail.mgm_bonus_content_notation_label': 'MGM Bonus Content Notation',
  'title_detail.mgm_related_ips_label': 'MGM Related IPs',
  'title_detail.target_amazon_maturity_rating_label': 'Target Amazon Maturity Rating',
  'title_detail.slate_info_header': 'Slate Info',
  'title_detail.record_status_label': 'Record Status',
  'title_detail.status_label': 'Status',
  'title_detail.target_release_date_label': 'Target Release Date',
  'title_detail.ownership_header': 'Ownership',
  'title_detail.responsible_team_label': 'Responsible Team',
  'title_detail.territory_label': 'Territory',
  'title_detail.title_points_of_contact_label': 'Title Points of Contact',
  'title_detail.content_info_header': 'Content',
  'title_detail.genres_label': 'Genre(s)',
  'title_detail.alternate_titles_header': 'Alternate Titles',
  'title_detail.production_header': 'Production',
  'title_detail.alternate_titles_table.type_header': 'Alternate Title Type',
  'title_detail.alternate_titles_table.title_header': 'Alternate Title',
  'title_detail.alternate_titles_table.language_header': 'Alternate Title Language',
  'title_detail.production_budget_label': 'Production Budget',
  'title_detail.production_country_label': 'Production Country',
  'title_detail.production_locations_label': 'Production Location(s)',
  'title_detail.post_production_country_label': 'Post-Production Country',
  'title_detail.post_production_locations_label': 'Post-Production Location(s)',
  'title_detail.working_genres_label': 'Working Genre',
  'title_detail.working_sub_genres_label': 'Working Sub-Genre',
  'title_detail.classification_tags_label': 'Classification Tags',
  'title_detail.seasons_header': 'Seasons',
  'title_detail.seasons_table.season_number': 'Season Number',
  'title_detail.seasons_table.episodes_ordered': 'Episodes Ordered',
  'title_detail.seasons_table.episodes_produced': 'Episodes Produced',
  'title_detail.seasons_table.release_year': 'Release Year',
  'title_detail.missing_info': 'not entered',
  'title_detail.seasons_table.season_details': 'Season Details',
  'title_detail.release_header': 'Release',
  'title_detail.target_releasing_entity_label': 'Releasing Entity',
  'title_detail.card_header_action_menu.flag_incorrect_data_label': 'Flag incorrect data',
  'title_detail.card_header_action_menu.request_new_field_label': 'Request new field',
  'title_detail.card_header_action_menu.request_new_field_input_label': 'Reason',
  'title_detail.card_header_action_menu.flag_incorrect_data_input_label': 'Explain the issue',
  'title_detail.card_header_action_menu.request_new_field_content': 'Send us your ideas for additional data that would be valuable to you.',
  'title_detail.card_header_action_menu.copy_link_label': 'Copy link',
  'title_detail.card_header_action_menu.flag_incorrect_data_content':
    'See something on this page that’s missing or incorrect? Let us know and we’ll look into it.',
  'title_detail.card_header_action_menu.flag_incorrect_data_action_button_cancel': 'Cancel',
  'title_detail.card_header_action_menu.flag_incorrect_data_action_button_submit': 'Submit For Review',
  'revision_history.header': 'Activity Feed',
  'preview_panel.overview_label': 'Overview',
  'app.revision_history.label.air_order.none': 'none',
  'app.revision_history.label.aka': 'AKA',
  'app.revision_history.label.audience_type': 'Audience Type',
  'app.revision_history.label.branding_type': 'Branding Type',
  'app.revision_history.label.change_target.deleted': '(deleted)',
  'app.revision_history.label.change_target.seasonPart_episodePart': 'Season {{seasonNumber}} Episode {{episodeNumber}}',
  'app.revision_history.label.change_target.episodePart': 'Episode {{episodeNumber}}',
  'app.revision_history.label.change_target.seasonPart': 'Season {{seasonNumber}}',
  'app.revision_history.label.content_securirty_tier': 'Content Security Tier',
  'app.revision_history.label.development_team': 'Development Team',
  'app.revision_history.label.display_name': 'Display Name',
  'app.revision_history.label.distribution_services': 'Distribution Services',
  'app.revision_history.label.season_number': 'Season Number',
  'app.revision_history.label.episode_status': 'Status',
  'app.revision_history.label.episode_number': 'Episode Number',
  'app.revision_history.label.episode.air_order': 'Air Order',
  'app.revision_history.label.episode.status.abandoned': 'Abandoned',
  'app.revision_history.label.episode.status.active': 'Active',
  'app.revision_history.label.fka': 'FKA',
  'app.revision_history.label.format': 'Format',
  'app.revision_history.label.genres': 'Genres',
  'app.revision_history.label.greenlight_status': 'Greenlight Status',
  'app.revision_history.label.is_original': 'Is Original',
  'app.revision_history.label.logline': 'Working Logline',
  'app.revision_history.label.notes': 'Notes',
  'app.revision_history.label.credited_companies': 'Credited Companies',
  'app.revision_history.label.original_language': 'Original Language',
  'app.revision_history.label.original_territory': 'Originating Territory',
  'app.revision_history.label.originating_teams': 'Originating Teams',
  'app.revision_history.label.ownership_type': 'Ownership Type',
  'app.revision_history.label.ownership': 'Ownership',
  'app.revision_history.label.programming_type': 'Programming Type',
  'app.revision_history.label.resolution': 'Resolution',
  'app.revision_history.label.rightsline.sync_status': 'Rightsline Sync Status',
  'app.revision_history.label.rightsline.template': 'Rightsline Template',
  'app.revision_history.label.season.initial_release_year': 'Initial Release Year',
  'app.revision_history.label.show_code': 'Show Code',
  'app.revision_history.label.time_format': 'Time Format',
  'app.revision_history.label.title': 'Title Name',
  'app.revision_history.label.work_type': 'Work Type',
  'app.revision_history.label.now_episode_info': 'now Episode {{currentNumber}}',
  'app.revision_history.label.now_season_info': 'now Season {{currentNumber}}',
  'app.revision_history.label.now_season': '{{oldNumber}} {{currentInfo}}',
  'app.revision_history.label.now_episode': '{{oldNumber}} {{currentInfo}}',
  'app.revision_history.label.season': 'Season',
  'app.revision_history.label.season_now_season': 'Season {{oldNumber}} {{currentInfo}}',
  'app.revision_history.label.season_episode': 'Season {{seasonNumber}} Episode',
  'app.revision_history.label.season_episode_now_episode': 'Season {{seasonNumber}} Episode {{oldNumber}} {{currentInfo}}',
  'app.revision_history.label.soft_deleted': 'Rightsline Entity: {sourceId}',
  'app.revision_history.label.scripted_unscripted': 'Scripted/Unscripted/Live',
  'app.revision_history.label.release_cadence': 'Release Cadence',
  'app.revision_history.label.release_status': 'Release Status',
  'app.revision_history.label.marketting_categorization': 'Marketting Categorization',
  'app.revision_history.label.release_date': 'Release Date',
  'app.original': 'Original',
  'app.licensed': 'Licensed',
};
